import React, { useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Navbar, Container, Nav, Image, Row, Col, Table, Stack } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Masonry from 'react-masonry-css'

const Main = () => {
  const imagesFlat = [
    {
      original: '/images/photos/flat/flat_1.JPG',
      thumbnail: '/images/photos/flat/flat_1.JPG',
      originalWidth: 600,
      thumbnailWidth: 600
    },
    {
      original: '/images/photos/flat/flat_2.JPG',
      thumbnail: '/images/photos/flat/flat_2.JPG',
      originalWidth: 600,
      thumbnailWidth: 600
    },
    {
      original: 'https://picsum.photos/id/1015/1000/600/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1019/1000/600/',
      thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },
  ];

  const [active, setActive] = useState('home');

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
    500: 1
  };

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
        <Container>
          <Navbar.Brand
            href="#home"
            onClick={() => setActive('home')}
            className="d-flex align-items-center"
          >
            <Image
              src="/images/logo.png"
              width="50"
              height="50"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
            <span className="m-2 d-inline-block"></span>
            <h1 className="m-0 d-inline-block">KLIDNÝ MALÝ BYT</h1>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="justify-content-end"
              style={{ width: "100%" }}
              activeKey={active}
              onSelect={(selectedKey) => selectedKey ? setActive(selectedKey) : null}
            >
              <Nav.Link eventKey="flat" href="#flat">Byt</Nav.Link>
              <Nav.Link eventKey="around" href="#around">Okolí</Nav.Link>
              <Nav.Link eventKey="contact" href="#contact">Kontakt</Nav.Link>
              {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container id="home" fluid className="section img-container">
        <Image src="/images/photos/mockup-wall-with-plants-shelf.jpg" fluid />
        <div className="overlay">
          <h1>nabízíme pronájem bytu v Novém Městě nad Metují</h1>
        </div>
      </Container>
      <Container id="flat" fluid className="section">
        <Row>
          <Col><h1>Byt</h1></Col>
        </Row>
        <Row>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            <img style={{
              maxWidth: "100%",
              height: "auto",

            }} alt="zámek" src="/images/photos/flat/flat_1.JPG" />
            <img style={{
              maxWidth: "100%",
              height: "auto"
            }} alt="zámek" src="/images/photos/flat/flat_2.JPG" />
            <img style={{
              maxWidth: "90%",
              height: "auto"
            }} alt="zámek" src="/images/photos/flat/flat_3.JPG" />
            <img style={{
              maxWidth: "90%",
              height: "auto"
            }} alt="zámek" src="/images/photos/flat/flat_4.JPG" />
            <img style={{
              maxWidth: "90%",
              height: "auto"
            }} alt="zámek" src="/images/photos/flat/flat_5.JPG" />
          </Masonry>
        </Row>
      </Container>
      <Container id="around" fluid className="section">
        <Row>
          <Col ><h1 >Okolí</h1></Col>
        </Row>
        <Row>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            <img style={{
              maxWidth: "100%",
              height: "auto",

            }} alt="zámek" src="/images/photos/around/around_1.jpg" />
            <img style={{
              maxWidth: "100%",
              height: "auto"
            }} alt="zámek" src="/images/photos/around/around_2.jpg" />
            <img style={{
              maxWidth: "90%",
              height: "auto"
            }} alt="zámek" src="/images/photos/around/around_3.jpg" />
            <img style={{
              maxWidth: "90%",
              height: "auto"
            }} alt="zámek" src="/images/photos/around/around_4.jpg" />
            <img style={{
              maxWidth: "90%",
              height: "auto"
            }} alt="zámek" src="/images/photos/around/around_5.jpg" />
          </Masonry>
        </Row>
      </Container>
      <Container id="contact" fluid className="section">
        <Row>
          <Col><h1>Kontakt</h1></Col>
        </Row>
        <Row>
          <Col md={3} className="m-4">
            <Row><h3 className="d-flex justify-content-center">Adresa</h3></Row>
            <Row className="d-flex justify-content-center">Kpt. Jaroše 108</Row>
            <Row className="d-flex justify-content-center">Nové Město nad Metují</Row>
            <Row className="d-flex justify-content-center">549 01</Row>
          </Col>
          <Col md={3} className="m-4">
            <Row className="d-flex justify-content-center"><h3 className="d-flex justify-content-center">Telefon</h3></Row>
            <Row><a className="d-flex justify-content-center" href="tel:+420777159870">777 159 870</a></Row>
          </Col>
          <Col md={3} className="m-4">
            <Row className="d-flex justify-content-center"><h3 className="d-flex justify-content-center">Email</h3></Row>
            <Row><a className="d-flex justify-content-center" href="mailto: monika.petrovkova@email.cz">monika.petrovkova@email.cz</a></Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <iframe title="mapa" style={{ border: "none" }} src="https://frame.mapy.cz/s/guperebapu" width="400" height="280" frameBorder="0"></iframe>
        </Row>
      </Container>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container fluid>
          <Row><Col><div style={{ color: "white", fontSize: "small" }}>© 2021</div></Col></Row>
          <Row><Col><a style={{ fontSize: "small" }} href="https://www.freepik.com/photos/flower">Flower photo created by vanitjan - www.freepik.com</a></Col></Row>
        </Container>
      </Navbar>
    </>
  );
}

const App = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
